<template>
  <div v-loading="loading">
    <template v-for="(item, index) in bidMembers">
      <el-card :key="index" style="margin-top: 5px">
        <div slot="header">
          <span>{{ item.userType | dict(userTypeList) }}</span>
        </div>
        <el-table ref="multipleTable" border max-height="300" :data="item.MemberList">
          <el-table-column align="center" width="70" type="index" label="序号"></el-table-column>
          <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
          <el-table-column align="center" prop="deptNames" label="部门"></el-table-column>
          <el-table-column align="center" prop="postName" label="岗位"></el-table-column>
          <el-table-column align="center" prop="phone" label="联系电话"></el-table-column>
          <el-table-column width="70" align="center" label="操作" v-if="canWrite">
            <template slot="header">
              <i class="iconfont iconadd-circle deit" @click="addMember(index, item)"></i>
            </template>
            <template slot-scope="scopes">
              <el-button
                type="text"
                class="text_Remove_Bgc"
                size="small"
                @click.stop="delMember(scopes, index, item)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </template>

    <el-dialog
      :visible.sync="showEdit"
      v-if="showEdit"
      :before-close="cancelSelect"
      append-to-body
      width="65%"
    >
      <div slot="title" style="color: #409eff">新增用户</div>
      <div class="mainList_operation_search">
        <DeptCascader :placeholder="'请选择部门'" :deptList="deptList" v-model="parameter.deptId" />

        <el-input
          class="mainList_operation_search_Name"
          style="width: 200px; margin: 0 8px 8px 8px"
          placeholder="姓名"
          clearable
          v-model="parameter.userName"
        ></el-input>
        <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)"
          >搜索</el-button
        >
        <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
      </div>
      <el-table
        v-loading="selectedListLoad"
        :data="selectedList"
        style="width: 100%"
        max-height="400"
        border
        id="selectedTable"
        :row-key="getRowKey"
        :row-class-name="tableRowClassName"
        @selection-change="handleCurrentChange"
        @row-click="handRowClick"
        :row-style="rowClass"
        ref="selectedTable"
      >
        <el-table-column
          type="selection"
          label="选择"
          :reserve-selection="true"
          :selectable="selectEnable"
          width="60"
        >
        </el-table-column>
        <el-table-column align="center" prop="deptNames" label="部门"> </el-table-column>
        <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
      </el-table>
      <div style="float: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChangeHandler"
          :current-page="parameter.pageNow"
          :page-size="parameter.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="total, prev, pager, next, slot, jumper"
          :total="parameter.total"
        >
          <span class="el-pagination__jump e_a_pagination">
            <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
            <span style="padding-top: 1px">条/页</span>
          </span>
        </el-pagination>
      </div>
      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button type="success" style="min-width: 120px; margin: 0 25px" @click="saveSelectedFn"
          >确 定</el-button
        >
        <el-button
          type="warning"
          style="min-width: 120px; margin: 0 25px"
          plain
          @click="cancelSelect"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'BidAssign',
  components: {
    SelectDialog: () => import('@/components/selectDialog.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  props: {
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
    editType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      bidMembers: [],
      userTypeList: [],
      deptList: [], //部门
      deleteBidMemberList: [], //删除人员数组
      selectMember: {
        //当前新增人员位置
        index: 0,
        userType: null,
      },
      showEdit: false, //选择弹窗
      arrSelect: [], //已选人员
      parameter: {
        deptId: null,
        userName: null,
        pageNow: 1,
        total: 1,
        pageSize: 50,
      },
      pageSize: 0,
      selectedListLoad: false,
      selectedList: [],
      temporaryList: [],

      selectRow: [], //用户选中高亮
    }
  },
  computed: {
    ...mapState({
      CompanyMembers: state => state.business.CompanyMembers,
      userInfo: state => state.user.userInfo,
      selectDeptList: state => state.business.selectDeptList,
      ZP_Members: state => state.bid.ZP_Members,
    }),
    canWrite() {
      switch (this.editType) {
        case 'add':
          return true
        case 'edit':
          return true
        case 'detail':
          return false
        case 'bidEdit':
          return false
        default:
          return false
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    if (this.selectDeptList.length <= 0) {
      this.getDeptList()
    } else {
      this.deptList = this.selectDeptList
    }
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.loading = true
        this.$api.dict
          .listSysDictData('BID_EARNEST_MEMBER', true)
          .then(res => {
            this.userTypeList = res.data
            this.bidMembers = []
            this.userTypeList.forEach(v => {
              this.bidMembers.push({
                userType: v.dictVal,
                MemberList: [],
              })
            })
            if (val.bidMembers) {
              val.bidMembers.forEach(v => {
                const matchingItem = this.bidMembers.find(item => v.userType === item.userType)
                if (matchingItem) {
                  matchingItem.MemberList.push(v)
                }
              })
              // this.bidMembers.forEach(v => {
              //   const matchingItem = val.bidMembers.find(item => v.userType === item.userType)
              // })
              this.loading = false
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      },
    },

    //监听是否更改了vuex中的ZP_Members数据
    ZP_Members: {
      handler(val) {
        this.loading = true

        let members = []
        this.bidMembers.forEach(item => {
          if (item.MemberList && item.MemberList.length > 0) {
            members = [...members, ...item.MemberList]
          }
          item.MemberList = []
        })
        let totalMembers = [...members, ...val]
        totalMembers = this.someMembers(totalMembers)
        totalMembers.forEach(v => {
          const matchingItem = this.bidMembers.find(item => v.userType === item.userType)
          if (matchingItem) {
            matchingItem.MemberList.push(v)
          }
        })
        this.loading = false
      },
    },

    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.selectRow = []
        if (val.length > 0) {
          this.selectRow = val.map(v => v.id)
        }
      },
    },
  },
  methods: {
    someMembers(arr) {
      let some = []
      arr.forEach(el => {
        if (!some.some(e => e.staffId == el.staffId && e.userType == el.userType)) {
          some.push(el)
        }
      })
      return some
    },
    // 删除成员
    delMember(scopes, idnex, item) {
      const member = this.bidMembers.find((v, i) => v.userType === item.userType && i === idnex)
      if (member) {
        member.MemberList.splice(scopes.$index, 1)
        if (scopes.row.id) {
          this.deleteBidMemberList.push(scopes.row.id)
        }
      }
    },
    // 打开弹窗
    addMember(index, item) {
      this.selectMember.index = index
      this.selectMember.userType = item.userType
      // 如果当前打开
      this.parameter.userName = null
      this.parameter.deptId = null
      this.parameter.pageNow = 1
      this.getSelectedList()
      // 展开树形选择器时勾选已有的值
      if (item.userType) {
        this.arrSelect = []
        const list = this.bidMembers.find(v => v.userType === item.userType)
        this.arrSelect = list.MemberList.map(item => item.staffId)
      }
      this.showEdit = true
      this.selectedListLoad = true
      this.$nextTick(() => {
        this.selectedList.forEach(v => {
          if (this.arrSelect.indexOf(v.id) != -1) {
            this.$refs.selectedTable.toggleRowSelection(v, true)
          } else {
            this.$refs.selectedTable.toggleRowSelection(v, false)
          }
        })
        this.selectedListLoad = false
      })
      this.showEdit = true
    },
    getRowKey(row) {
      return row.id
    },
    selectEnable(row, rowIndex) {
      if (this.arrSelect.indexOf(row.id) != -1) {
        return false
      }
      return true
    },

    // 获取所有人员
    getSelectedList() {
      this.selectedListLoad = true
      this.$api.user
        .listStaff(this.parameter)
        .then(res => {
          this.selectedList = res.data?.records.deepClone()
          this.parameter.total = res.data?.total
          this.$nextTick(() => {
            this.selectedList.forEach(v => {
              if (this.arrSelect.indexOf(v.id) != -1) {
                this.$refs.selectedTable.toggleRowSelection(v, true)
              }
            })
          })
          this.selectedListLoad = false
        })
        .catch(err => {
          console.log(err)
          this.selectedListLoad = false
        })
    },
    handleReset() {
      this.parameter.userName = null
      this.parameter.deptId = null
      this.parameter.pageNow = 1
      this.getSelectedList()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getSelectedList()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getSelectedList()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getSelectedList()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    //部门
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {})
    },

    cancelSelect() {
      this.showEdit = false
      this.temporaryList = []
    },

    saveSelectedFn() {
      let arr = []
      this.temporaryList.forEach(v => {
        if (this.arrSelect.indexOf(v.id) == -1) {
          arr.push({
            deptId: v.deptList[0],
            deptNames: v.deptNames,
            phone: v.phone,
            postName: v.postName,
            staffId: v.id,
            userName: v.userName,
            userType: this.selectMember.userType,
          })
        }
      })
      const item = this.bidMembers.find(
        (v, i) => v.userType === this.selectMember.userType && i === this.selectMember.index
      )
      if (item) {
        arr.forEach(v => {
          item.MemberList.push(v)
        })
      }

      this.showEdit = false
      this.temporaryList = []
    },

    handleCurrentChange(val) {
      this.temporaryList = val
    },
    // 对所选行进行样式设置
    rowClass({ row, rowIndex }) {
      if (this.selectRow.includes(row.id)) {
        return { 'background-color': '#ede88c' }
      }
    },
    // 点击行选中
    handRowClick(row) {
      if (this.arrSelect.indexOf(row.id) == -1) {
        this.$refs.selectedTable.toggleRowSelection(row)
      }
    },
    // 选中高亮
    tableRowClassName({ row, column, rowIndex, columnIndex }) {
      if (this.arrSelect.indexOf(row.id) != -1) {
        return ['tableRowClassName']
      }
      return []
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0 5px;
}

/deep/#selectedTable {
  .tableRowClassName {
    background-color: #ede88c;
  }

  tbody tr:hover > td {
    background-color: unset !important; //修改成自己想要的颜色即可
  }
}
</style>
